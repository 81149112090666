@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #000000;
  color: white;
  height: 100vh;
  padding: 1rem;
  box-sizing: border-box;
  overflow: hidden;
}
input {
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
  color: white;
  width: 100%;
}

.custom-caret {
  width: 0.5em; /* Adjust width to make it longer */
  height: 0.25em; /* Adjust height to make it thicker */
  background: white;
  position: absolute;
  pointer-events: none; /* Ensure caret doesn't interfere with input events */
  bottom: -0.05em; /* Position slightly lower */
  transition: opacity 0.2s ease-in-out; /* Add a transition for smooth visibility changes */
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.help-commands {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.help-command {
  color: white;
  padding-left: 2rem;
}

.help-description {
  color: white;
}

.contact-method {
  color: white;
  padding-left: 2rem;
}

@media (max-width: 576px) {
  .ascii-art {
    font-size: 9px;
  }
  .help-commands {
    display: grid;
    grid-template-columns: 1fr 1.3fr;
    gap: 0.7rem;
  }
  .contact-info {
    display: grid;
    grid-template-columns: 1fr 1.3fr;
    gap: 0.7rem;
  }
}

@media (min-width: 577px) {
  .ascii-art {
    font-size: 15px;
  }
  .help-commands {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 0.5rem;
  }
  .contact-info {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    gap: 0.5rem;
  }
}

@media (min-width: 768px) {
  .help-commands {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 0.3rem;
  }
  .contact-info {
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 0.3rem;
  }
}

@media (min-width: 993px) {
  .help-commands {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 0.1rem;
  }
  .help-command {
    color: white;
    padding-left: 2rem;
  }
  .contact-info {
    display: grid;
    grid-template-columns: 1fr 4fr;
    gap: 0.1rem;
  }
 }